<script setup lang="ts">
import CookiesAgreementBanner from '~/components/layout/CookiesAgreementBanner.vue';

provide('theme', 'light');

onMounted(() => {
  document.body.classList.add('dark');
  document.body.classList.remove('light');

  document.body.classList.add('external');
  document.body.classList.remove('internal');
});
</script>

<template>
  <ClientOnly>
    <main id="main">
      <slot />
    </main>

    <CookiesAgreementBanner />
  </ClientOnly>
</template>
